@import '../../css/utils';

.heading {
  font-weight: bold;
}

.base {
  font-family: 'Lato', 'Arial', sans-serif;
  font-weight: normal;
  margin: 0;
  padding: 0;

  p {
    margin-bottom: 24px;
  }

  ol {
    padding: 0;
    list-style: none;
    counter-reset: list-counter;
  }
  ol li {
    margin-bottom: 12px;
    counter-increment: list-counter;
  }
  ol li::before {
    content: counter(list-counter) '. ';
    font-weight: bold;
  }

  *:last-child {
    margin-bottom: 0;
  }

  a {
    font-weight: bold;
    color: $light-blue;
    text-decoration: underline;
  }
}

.h1 {
  @extend .heading;
  font-size: 42px;
  line-height: 48px;
  font-weight: bold;
}

.h2 {
  @extend .heading;
  font-size: 32px;
  line-height: 38px;
}

.h3 {
  @extend .heading;
  font-size: 28px;
  line-height: 34px;
}

.h4 {
  @extend .heading;
  font-size: 24px;
  line-height: 30px;
}

.h5 {
  @extend .heading;
  font-size: 20px;
  line-height: 26px;
}

.h6 {
  @extend .heading;
  font-size: 18px;
  line-height: 24px;
}

.subtitle {
  font-size: 16px;
  line-height: 22px;
}

.body1 {
  font-size: 14px;
  line-height: 20px;
}

.body2 {
  font-size: 12px;
  line-height: 18px;
}

.footnote {
  font-size: 10px;
  line-height: 14px;
}

.breadcrumb {
  font-size: 16px;
}

.gutterBottom {
  margin-bottom: 16px;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.glitchLeft {
  color: #00fff9;
  offset-position: 10ch 8em;
}
