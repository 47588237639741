$dark-blue: #1f2532;
$light-blue: #296ebd;
$shade-blue: #e4f0f4;
$shade-blue-dark: #d3e2e7;
$white: #FFF;

$light-blue-hover: #4277b3;
$dark-blue-hover: #181d27;

$border: rgba(107, 119, 128, 0.4);

$red: #eb5757;
$shade-red: #ffc3c3;
$green: #6fcf97;

$error-red: #eb5757;
$warning-orange: #f2994a;
$success-green: #6fcf97;

$black: #202020;
$light-grey: #6b7780;
$lighter-grey: #acb3b9;
$lightest-grey: #f9f9f9;
$lightest-offgrey: #f5f5f9;
$disabled-grey: #e4e4eb;
$hover-grey: #f5f7f8;
$white: #ffffff;
$disabled-dropdown-grey: #f4f4f4;

$insta-gradient: linear-gradient(
  90deg,
  rgba(131,58,180,1) 0%,
  rgba(253,29,29,1) 50%,
  rgba(252,176,69,1) 100%
);
