@import '../../css/utils';

.footer {
  padding: 20px 30px;
  box-sizing: border-box;
  display: flex;
  border-top: 0.5px solid #FFFFFF4D;

  .copyright {
    font: normal normal medium 14px/20px Raleway;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.75;
    margin-top: 1em;
  }

  .category {
    width: 25%;
    display: flex;
    flex-direction: column;
    margin-top: 1em;
  }

  .legal {
    // font: normal normal medium 12px/20px Raleway;
    font-size: 0.7em;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.75;
    padding-bottom: 0.3em;
  }

  .title {
    font-weight: bold;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding-bottom: 0.8em;
  }

  .item {
    // font: normal normal medium 12px/20px Raleway;
    font-size: 0.9em;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.75;
    padding-bottom: 0.3em;
  }

}

.rights {
  // font: normal normal medium 12px/20px Raleway;
  font-size: 0.8em;
  letter-spacing: 0px;
  color: grey;
  opacity: 0.75;
  height: 2em;
  display: block;
  padding-top: 2em;
  padding-bottom: 1em;
  text-align: center;
}
