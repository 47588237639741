@import '../../css/utils';

@media (min-width: 600px) {
  .display-mobile {
    display: none;
  }

  .header-title-mobile {
    display: none;
  }
}

@media (max-width: 600px) {
  .display-desktop {
    display: none;
  }

  .header-title {
    display: none;
  }
}

.header {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 2em;
  box-sizing: border-box;
  border-bottom: 0.5px solid #FFFFFF4D;

  @media (max-width: 600px) {
    display: flex;
    // justify-content: center;
  }

  .header-contact {
    text-decoration: none;
  }

  .header-icon {
    height: 1em;
    vertical-align: middle;
  }

  .header-title-mobile {
    font-size: 1rem !important;
    padding-right: 1em;
  }

  
  .logo {
    display: flex;
    align-items: center;
    text-decoration: none;

    .header-logo {
      padding-right: 1em;
    }

    @media (max-width: 600px) {
      .header-logo {
        height: 2em;
      }

      .header-title {
        font-size: 1em;
      }
    }

    span {
      font-family: 'PTMono-Bold', 'PT Mono', monospace;
      letter-spacing: 0px;
      font-size: 2rem;
      font-weight: bolder;
    }
  }
}
