@import "./css/utils";

.wrapper {
  min-height: 100vh;
  height: 100%;
  min-width: 100%;
  background: rgb(124,251,248);
  background: -moz-linear-gradient(90deg, rgba(124,251,248,0.25) 0%, rgba(0,0,0,1) 25%, rgba(0,0,0,1) 75%, rgba(231,57,188,0.25) 100%);
  background: -webkit-linear-gradient(90deg, rgba(124,251,248,0.25) 0%, rgba(0,0,0,1) 25%, rgba(0,0,0,1) 75%, rgba(231,57,188,0.25) 100%);
  background: linear-gradient(90deg, rgba(124,251,248,0.25) 0%, rgba(0,0,0,1) 25%, rgba(0,0,0,1) 75%, rgba(231,57,188,0.25) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7cfbf8",endColorstr="#e739bc",GradientType=1);

  .main {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    min-height: 100vh;
  }

  .form {
    margin-top: 10%;
    margin-left: 20%;
    margin-right: 20%;
    min-height: 60vh
  }
}
