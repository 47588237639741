$animation-time-default: 0.3s;
$animation-type-easyinout: ease-in-out;

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
