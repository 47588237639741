@import "../../css/utils";


@media (min-width: 600px) {
  .display-mobile {
    display: none;
  }
  .title-img {
    height: 5em;
  }
  .title-img-small {
    height: 3em;
    padding-top: 5em;
    padding-bottom: 2em;
  }
  .heading {
    padding-bottom: 5em;
    padding-top: 5em;
  }
}

@media (max-width: 600px) {
  .display-desktop {
    display: none;
  }
  .title-img {
    height: 3em;
  }
  .title-img-small {
    height: 1.5em;
    padding-top: 2em;
    padding-bottom: 1em;
  }
  .heading {
    padding-bottom: 2em;
    padding-top: 3em;
  }
}


.landing-container {
  min-height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;

  .hero {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    margin: 50px 0 100px;

    @include media('>md') {
      flex-direction: row;
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 0 32px;

      img {
        max-width: 500px;
        width: 100%;
        height: auto;
      }

      .description {
        font: normal normal 600 20px/28px Raleway;
        letter-spacing: 0px;
        color: #FFFFFFD9;
        opacity: 1;
        max-width: 500px;
        margin-top: 60px;
      }
    }

    .binary {
      max-width: 490px;
      width: 100%;
      height: auto;
    }
  }
  .reach-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5em;
    flex-direction: column;

    @include media('>md') {
      flex-direction: row;
    }

    .reach {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include media('>md') {
        &:not(:last-of-type) {
          margin-right: 100px;
        }
      }
      .value {
        font: normal normal bold 50px/100px PT Mono;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
      }
      .info {
        font: normal normal medium 20px/77px Raleway;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
      }
    }
  }
  .stake-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-bottom: 250px;

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px;
      // padding: 0 32px;
      width: 100%;

      img {
        max-width: 600px;
        width: 100%;
        height: auto;
        margin-bottom: 30px;
      }

      .info {
        font: normal normal 600 20px/28px Raleway;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        max-width: 750px;
        width: 100%;
      }
    }
    .cards {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include media('>md') {
        flex-direction: row;
      }

      .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 250px;
        height: 270px;
        padding: 20px;
        padding-bottom: 10px;
        box-sizing: border-box;
        margin-bottom: 16px;

        background: #000000 0% 0% no-repeat padding-box;
        border: 0.5px solid #FFFFFF33;
        opacity: 1;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);

        @include media('>md') {
          &:not(:last-of-type) {
            margin-right: 20px;
          }
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            z-index: 1;
            width: 80px;
            height: 80px;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .title {
            font: normal normal bold 20px/77px Raleway;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            margin: 0;
          }
          .description {
            // width: 320px;
            height: 120px;
            font: normal normal normal 12px/22px Raleway;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
          }
        }
      }
    }
    .cards-staking {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include media('>md') {
        flex-direction: row;
      }

      @media (max-width: 1000px) {
        flex-direction: row;
        // width: 20em;
      }

      .card-network {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 120px;
        height: 120px;
        padding: 5px;
        box-sizing: border-box;
        margin-bottom: 16px;

        background: #000000 0% 0% no-repeat padding-box;
        border: 0.5px solid #FFFFFF33;
        opacity: 1;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);

        @include media('>md') {
          &:not(:last-of-type) {
            margin-right: 20px;
          }
        }

        @media (max-width: 600px) {
          width: 4em;
          height: 4em;
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            z-index: 1;
            width: 80px;
            height: 80px;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .title {
            font: normal normal bold 20px/77px Raleway;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            margin: 0;
          }
          .description {
            // width: 320px;
            height: 120px;
            font: normal normal normal 12px/22px Raleway;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
          }
        }
      }
    }
  }
  .supported-networks-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;

    img {
      max-width: 468px;
      height: 5em;
      // width: 100%;
      // height: auto;
      // margin-bottom: 60px;
    }
    .networks {
      width: 100%;
      .networkRight {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;

        @include media('>md') {
          flex-direction: row;
        }
        .company-wrapper {
          max-width: 242px;
          width: 100%;
          background: #FFFFFF4D;
          color: #FFFFFF;
          padding: 0.5px;
          position: relative;
          margin-right: 20px;
          margin-bottom: 20px;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border-top: 67px solid #FFFFFF4D;
            border-right: 67px solid transparent;
            width: 0;
            z-index: 1;
          }

          .company {
            padding: 40px 70px 30px;
            background: #000000 0% 0% no-repeat padding-box;
            opacity: 1;
            width: 100%;
            height: 100%;
            box-sizing: border-box;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            &:before {
              content: '';
              position: absolute;
              top: -4px;
              left: -4px;
              border-top: 74px solid #000000;
              border-right: 74px solid transparent;
              width: 0;
              z-index: 2;
            }

            svg {
              margin-bottom: 30px;
            }

            .network-title {
              font: normal normal bold 17px/17px Raleway;
              letter-spacing: 0px;
              color: #FFFFFF;
              opacity: 1;
              margin-bottom: 10px;
            }

            .network-type {
              opacity: 1;
              margin-bottom: 30px;
              display: flex;
              align-items: center;

              span {
                font: normal normal 700 13px/13px Raleway;
                letter-spacing: 0px;
                color: #FFFFFF;
              }
              div {
                width: 10px;
                height: 9px;
                background-color: white;
                margin-right: 3px;
                border-radius: 50%;
              }
            }
            .apr {
              font: normal normal normal 22px/22px PT Mono;
              letter-spacing: 0px;
              color: #FFFFFF;
              opacity: 1;
            }
          }
        }
      }
        .networkLeft {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 20px;
  
          @include media('>md') {
            flex-direction: row;
          }
          .company-wrapper {
            max-width: 242px;
            width: 100%;
            background: #FFFFFF4D;
            color: #FFFFFF;
            padding: 0.5px;
            position: relative;
            margin-right: 20px;
            margin-bottom: 20px;
  
            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              border-top: 67px solid #FFFFFF4D;
              border-right: 67px solid transparent;
              width: 0;
              z-index: 1;
            }
  
            .company {
              padding: 40px 70px 30px;
              background: #000000 0% 0% no-repeat padding-box;
              opacity: 1;
              width: 100%;
              height: 100%;
              box-sizing: border-box;
  
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
  
              &:before {
                content: '';
                position: absolute;
                top: -4px;
                left: -4px;
                border-top: 74px solid #000000;
                border-right: 74px solid transparent;
                width: 0;
                z-index: 2;
              }
  
              svg {
                margin-bottom: 30px;
              }
  
              .network-title {
                font: normal normal bold 17px/17px Raleway;
                letter-spacing: 0px;
                color: #FFFFFF;
                opacity: 1;
                margin-bottom: 10px;
              }
  
              .network-type {
                opacity: 1;
                margin-bottom: 30px;
                display: flex;
                align-items: center;
  
                span {
                  font: normal normal 700 13px/13px Raleway;
                  letter-spacing: 0px;
                  color: #FFFFFF;
                }
                div {
                  width: 10px;
                  height: 9px;
                  background-color: white;
                  margin-right: 3px;
                  border-radius: 50%;
                }
              }
              .apr {
                font: normal normal normal 22px/22px PT Mono;
                letter-spacing: 0px;
                color: #FFFFFF;
                opacity: 1;
              }
            }
          }
        }

        .info {
          padding: 50px 40px 40px 30px;
          box-sizing: border-box;
          max-width: 900px;
          background: #000000 0% 0% no-repeat padding-box;
          border: 0.5px solid rgba(255, 255, 255, 0.3019607843);
          opacity: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 2em;

          .box {
            width: 50%;
          }

          .smallerbox {
            width: 48%;
          }

          .imagebox {
            text-align: center;
          }

          .description {
            font: normal normal normal 15px/28px Raleway;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
          }

          .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 1em;

            .website {
              display: flex;
              flex-direction: column;

              span {
                font: normal normal bold 15px/28px Raleway;
                letter-spacing: 0px;
                color: #FFFFFF;
                opacity: 1;
              }

              a {
                text-decoration: underline;
                font: normal normal normal 15px/28px Raleway;
                letter-spacing: 0px;
                color: #FFFFFF;
                opacity: 1;
              }
            }
          }
        }

    }
  }
  .portfolio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10em;

    @media (max-width: 600px) {
      margin-bottom: 3em;
    }

    .title {
      font: normal normal bold 40px/43px Raleway;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
    }

    .sub {
      font: normal normal normal 18px/23px Raleway;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
    }

    .portfolios {
      display: flex;
      align-items: center;
      flex-direction: column;

      @media (min-width: 600px) {
        margin-top: 60px;
      }

      @include media('>md') {
        flex-direction: row;
      }

      .portfolio {
        width: 10em;
        height: 1em;
        padding: 50px 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000000 0% 0% no-repeat padding-box;
        border: 0.5px solid #FFFFFF4D;
        opacity: 1;

        margin-bottom: 16px;

        @include media('>md') {
          &:not(:last-of-type) {
            margin-right: 20px;
          }
        }
      }

      .venture {
        height: 3em;
      }
    }
  }
  .team-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5em;

    @media (max-width: 600px) {

      .title-img {
        height: 3em;
      }

      .heading {
        padding-bottom: 2em;
        padding-top: 3em;
      }

    }

    @media (min-width: 600px) {
      .title-img {
        height: 5em;
      }
      .heading {
        padding-bottom: 5em;
        padding-top: 5em;
      }
    }
    .members {
      display: flex;

      .member {
        padding: 20px;
        background: #000000 0% 0% no-repeat padding-box;
        border: 0.5px solid #FFFFFF33;
        opacity: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        // @include media('>md') {
        //   &:not(:last-of-type) {
        //     margin-right: 20px;
        //   }
        // }
        @media (max-width: 600px) {
          width: 3em;
          padding: 0.5em;
          margin: 0.2em;
        }

        @media (min-width: 600px) and (max-width: 1000px) {
          width: 7em;
          padding: 1em;
          margin: 0.2em;
        }


        .pfp {
          filter: grayscale(100%);
          
          @media (max-width: 600px) {
            // width: 4em;
            margin-bottom: 0.5em;
          }  
          @media (min-width: 600px) {

            margin-bottom: 0.5em;
          }
          @media (min-width: 1000px) {
            width: 180px;
            margin-bottom: 30px;
          }
        }

        .name {
          font: normal normal bold 15px/20px Raleway;
          letter-spacing: 0px;
          color: #FFFFFF;
          opacity: 1;
          @media (max-width: 600px) {
            font-size: 0.5em;
            font: normal normal bold 0.5em/10px Raleway;
          }
        }
        .title {
          font: normal normal 600 12px/60px Raleway;
          letter-spacing: 0px;
          color: #FFFFFF;
          opacity: 1;
          @media (max-width: 1000px) {
            display: none;
          }
        }
        .bio {
          font: normal normal normal 12px/28px Raleway;
          letter-spacing: 0px;
          color: #FFFFFF;
          opacity: 1;
        }
      }
    }
  }
}
